<template>
	<div class="ordermange">
		<div class="filter-container">
			<div class="filter-item">
				<label class="label">关键字: </label>
				<el-select v-model="searchType" style="width: 150px;margin-right: 10px;" @change="keyChange">
					<el-option v-for="item in goodsSelect" :key="item.id" :label="item.value" :value="item.id">
					</el-option>
				</el-select>
				<el-input v-model="searchKey" :placeholder="placeholders" @keyup.enter.native="goodsFilter" style="width: 250px;;margin-right: 10px;"
				 clearable></el-input>
			</div>
		</div>
		<div class="filter-container">
			<div class="filter-item">
				<label class="label">时间筛选: </label>
				<el-select v-model="timeType" style="width: 120px;margin-right: 10px;">
					<el-option v-for="item in timeTypeList" :key="item.id" :label="item.value" :value="item.id">
					</el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<!-- :picker-options="pickerOptions" -->
				<el-date-picker type="datetime" placeholder="选择日期" v-model="starTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
				 format="yyyy-MM-dd HH:mm:ss" :picker-options='maxOptions' default-time="00:00:00"></el-date-picker> ~
				<el-date-picker type="datetime" placeholder="选择日期" v-model="endTime" style="width: 250px;" value-format="yyyy-MM-dd HH:mm:ss"
				 format="yyyy-MM-dd HH:mm:ss" :picker-options='minOptions' default-time="23:59:59"></el-date-picker>
			</div>
		</div>
		<div class="filter-container">
			<div class="filter-item">
				<label class="label">订单状态: </label>
				<el-select v-model="orderStage" style="width: 200px;margin-right: 10px;" multiple>
					<!-- <el-option label="全部" :value="null"></el-option> -->
					<el-option v-for="item in orderStageList" :key="item.Value" :label="item.Text" :value="item.Value">
					</el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<label class="label">退款信息: </label>
				<el-select v-model="RefundInfo " style="width: 200px;margin-right: 10px;">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in RefundInfoList" :key="item.id" :label="item.value" :value="item.id">
					</el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<label class="label">是否加星: </label>
				<el-select v-model="isStart" style="width: 200px;margin-right: 10px;">
					<el-option label="全部" :value="null"></el-option>
					<el-option v-for="item in isStartList" :key="item.id" :label="item.value" :value="item.id">
					</el-option>
				</el-select>
			</div>
			<div class="filter-item">
				<el-button type="primary" style="margin-left:30px;width:90px;" @click="goodsFilter">查询</el-button>
				<buttonPermissions :datas="'downloadOrder'">
					<el-button style="margin-left:30px;width:90px;" @click="exportFun">导出</el-button>
				</buttonPermissions>
			</div>
			<div class="filter-item">
				<buttonPermissions :datas="'batchOrder'">
					<el-button style="margin-left:30px;" @click="bathsendOrder">批量发货</el-button>
				</buttonPermissions>
			</div>
		</div>
		<!-- 表格 -->
		<div class="table-container" style="margin-top:20px">
			<el-table :data="groupData" style="width: 100%;" v-loading="loading" ref="multipleTable" :row-key="rowKeys"
			 default-expand-all :row-class-name="setClassName">
				<el-table-column type="expand">
					<template slot-scope="scope" v-if="scope.row.CustomerRemark||scope.row.SystemRemark">
						<div style="padding:15px 0">
							<div style="background:rgba(217, 236, 255, 1);color:rgba(64, 158, 255, 1);padding:10px;word-wrap:break-word;width:95%;"
							 v-if="scope.row.CustomerRemark">买家留言:{{scope.row.CustomerRemark}}</div>
							<div v-if="scope.row.SystemRemark" style="background:rgba(253, 246, 236, 1);color:rgba(253, 162, 60, 1);padding:10px;word-wrap:break-word;width:95%;">系统备注:{{scope.row.SystemRemark}}</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="OrderNo" label="订单号" width="150px">
					<template slot-scope="scope">
						<div style="color:red">[{{scope.row.SendTypeDisplay}}]</div>
						<div>{{scope.row.OrderNo}}</div>
						<!-- 仅到店自提 且待发货的订单才展示 -->
						<!-- <buttonPermissions :datas="'changeOrderWay'">
							<p style="color: rgb(64, 158, 255);cursor: pointer;" v-if="scope.row.OrderState==3&&(scope.row.SendType==1)"
							 @click="editeSendFun(scope.row)">修改配送方式</p>
						</buttonPermissions> -->
					</template>

				</el-table-column>
				<el-table-column prop="AddTime" label="下单时间"></el-table-column>
				<el-table-column prop="PayTime" label="支付时间">
					<template slot-scope="scope">
						<div v-if="scope.row.OrderState==1">未支付</div>
						<div v-else>{{scope.row.PayTime}}</div>
					</template>
				</el-table-column>
				<el-table-column prop="OrderStateDisplay" label="订单状态"></el-table-column>
				<el-table-column prop="OrderMoney" label="支付金额">
					<template slot-scope="scope">
						<div>{{scope.row.OrderMoney}}</div>
						<!-- 仅待付款并不是 积分兑换订单展示 -->
						<!-- <buttonPermissions :datas="'changePrice'">
							<div style="color: rgb(64, 158, 255);cursor: pointer;" v-if="scope.row.OrderState==1&&(scope.row.OrderType!=3)"
							 @click="editeMoneyFun(scope.row)">修改金额</div>
						</buttonPermissions> -->

					</template>
				</el-table-column>
				<el-table-column label="下单人" width="150">
					<template slot-scope="scope">
						<!-- <div style="color:#409EFF;cursor: pointer;width:140px;overflow:hidden;height:25px;white-space: nowrap;text-overflow: ellipsis;"
						 @click="customerFun(scope.row)">{{scope.row.CustomerNameDisplay}}</div> -->
						<div>{{scope.row.ReceiveName}}</div>
						<div>{{scope.row.ReceivePhone}}</div>
					</template>
				</el-table-column>
				<el-table-column label="退款信息" prop="RefundInfo">
					<template slot-scope="scope">
						<div style="color:rgb(247, 186, 42);">{{scope.row.RefundInfo}}</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" width="240">
					<template slot-scope="scope">
						<!-- 订单状态 待付款:1   待审单：2 待发货：3  已发货：4 已完成：5  已关闭：8 -->
						<el-button type="text" @click="lookOrderDetails(scope.row)" class="pointers">详情</el-button>
						<!-- <buttonPermissions :datas="'cancelOrder'" style="margin: 0 5px;">
							<el-button type="text" class="pointers" @click="closeOrder(scope.row)" v-if="scope.row.OrderState==1">关闭订单</el-button>
						</buttonPermissions> -->
						<el-popover placement="top-start" width="160" v-if="!scope.row.Star" style="margin: 0 5px;">
							<el-rate v-model="scope.row.Star" @change="rateChange(scope.row)"></el-rate>
							<el-button slot="reference" type="text" class="pointers" @click="addStar(scope.row)">加星</el-button>
						</el-popover>
						<el-popover placement="top-start" width="160" style="margin: 0 5px;" v-else>
							<el-rate v-model="scope.row.Star" @change="rateChange(scope.row)"></el-rate>
							<span slot="reference" @click="addStar(scope.row)">
								<span style="vertical-align: middle;">{{scope.row.Star}}</span>
								<el-rate style="display: inline-block;vertical-align: middle;" v-model="startCount" :max="1"></el-rate>
							</span>

						</el-popover>

						<!-- <el-button type="text" class="pointers" @click="checkOrders(scope.row)" :loading="loading" v-if="scope.row.OrderState==2">审单</el-button> -->
						<buttonPermissions :datas="'sendOrder'" style="margin: 0 5px;">
							<el-button type="text" class="pointers" @click="sendFunctionNo(scope.row)" v-if="scope.row.OrderState==3&&(scope.row.RefundInfo=='退款中')">发货</el-button>
							<el-button type="text" class="pointers" @click="sendFunction(scope.row)" v-if="scope.row.OrderState==3&&(scope.row.RefundInfo!='退款中')">发货</el-button>
						</buttonPermissions>

						<el-button type="text" class="pointers" style="margin: 0 5px;" @click="lookLogest(scope.row)" v-if="scope.row.OrderState==4||scope.row.OrderState==5">查看物流</el-button>
						<buttonPermissions :datas="'addRemark'">
							<el-button type="text" class="pointers" @click="orderMark(scope.row)">备注</el-button>
						</buttonPermissions>
					</template>
				</el-table-column>
			</el-table>

			<div style="text-align: right;">
				<el-pagination v-if="Total" style="margin:20px 0" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				 :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]" :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper"
				 :total="Total">
				</el-pagination>
			</div>
		</div>

		<!-- 订单发货的弹框 -->
		<!-- 快递 -->
		<el-dialog title="发货信息" :visible.sync="SendDialogVisible" width="850px" :before-close="closeDeliver">
			<el-form :model="sendForm" ref="sendForm" :rules="rules">
				<el-form-item label="物流公司 :" label-width="150px" prop="ExpressId">
					<el-select v-model="sendForm.ExpressId" placeholder="请选择物流公司" style="width:300px;">
						<el-option v-for="(i,index) in  ExpressList" :key="index" :label="i.ExpressName" :value="i.Id"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="物流单号 :" label-width="150px" prop="ExpressNo">
					<!-- 					onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
					 onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^0-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}" -->
					<!-- @keydown="handleInput2" -->
					<input v-model="sendForm.ExpressNo" style="padding:0 10px;width:300px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;">
					<!-- <el-input type="number" v-model="sendForm.ExpressNo" size="small" style="width:300px" clearable></el-input> -->
				</el-form-item>
			</el-form>
			<div style="text-align: center;">
				<el-button @click="closeDeliver" style="width:120px">取 消</el-button>
				<el-button type="primary" @click="submitSends('sendForm')" :loading="loading" style="width:120px">确认</el-button>
			</div>
		</el-dialog>

		<!-- 自提 -->
		<el-dialog :visible.sync="noSendDialogVisible" width="360px">
			<div class="sendClass">该单选择的配送方式为到店自提，等到买家到店，核销提货二维码后，订单状态自动更新为已发货</div>
			<div style="text-align: center;margin-top:20px">
				<el-button type="primary" @click="noSendDialogVisible=false">好 的</el-button>
			</div>
		</el-dialog>

		<!-- 点审单、发货 但是有退款 -->
		<!-- 		<el-dialog :visible.sync="chenckDialogVisible" width="360px">
			<div class="sendClass">订单当中存在未处理完成的退款单，请先对相关退款进行处理。</div>
			<div style="text-align: center;margin-top:20px">
				<el-button type="primary" @click="chenckDialogVisible=false">好 的</el-button>
			</div>
		</el-dialog> -->


		<!-- 关闭订单 -->
		<el-dialog title="关闭订单" :visible.sync="closeorderDialogVisible" width="520px">
			<el-form :model="formate" ref="formate" :rules="rules">
				<el-form-item label="关闭理由 :" label-width="110px" prop="closeReason">
					<el-select v-model="formate.closeReason" placeholder="请选择订单关闭理由" style="width:300px;">
						<el-option v-for="(i,index) in  closeReasonList" :key="index" :label="i" :value="i"></el-option>
					</el-select>
				</el-form-item>
			</el-form>
			<div style="text-align: center;margin-top:20px">
				<el-button @click="closeorderFun">关 闭</el-button>
				<el-button type="primary" @click="submitclose('formate')" :loading="loading">确认</el-button>
			</div>
		</el-dialog>

		<!-- 批量审单 -->
		<div class="bath-checkorders">
			<el-dialog :visible.sync="bathDialogVisible" width="500px">
				<div class="sendClass">即将为您批量处理全部待审单订单。在批量处理之前，请您先查看以下提示：</br>
					1.批量审单开始后，请耐心等待处理完成，勿重复操作；</br>
					2.若订单内存在退款中的商品或订单数据有更新，将审单失败</div>
				<div style="text-align: center;margin-top:20px">
					<!-- <el-button @click="bathDialogVisible=false">关 闭</el-button> -->
					<el-button type="primary" @click="startBathorder" :loading="loading">开始批量审单</el-button>
				</div>
			</el-dialog>
		</div>

		<!-- 批量审单进度条 -->
		<!-- 批量进行中 -->
		<el-dialog :title="batchTitle" :visible.sync="bathtaggelVisible" width="500px">
			<p style="color:#333;font-size: 12px;text-align: center;" v-if="CompleteCount!=TotalCount">已处理{{CompleteCount}}个订单，剩余{{TotalCount-CompleteCount}}个订单</p>
			<p style="color:#333;font-size: 12px;text-align: center;" v-if="CompleteCount==TotalCount">审单成功{{SuccessCount}}个订单，审单失败{{TotalCount-SuccessCount}}个订单</p>
			<el-progress :text-inside="false" :stroke-width="24" :percentage="pragrassNums" status="success"></el-progress>
			<div style="text-align: center;margin-top:30px">
				<el-button @click="craptaggle" v-if="CompleteCount!=TotalCount&& IsAllowStop&&(!IsStop)">中断审单</el-button>
				<el-button @click="prograssHide" v-if="CompleteCount!=TotalCount">收起进度</el-button>
				<!-- bathtaggelVisible=false -->
				<el-button @click="CloseprograssDia" v-if="CompleteCount==TotalCount">关 闭</el-button>
			</div>
		</el-dialog>

		<!-- 收起进度条的样式 -->

		<div class="prograss-box">
			<div @click="clickLookToggle(1)" v-show="pragrassSuccess">
				<el-alert title="<  批量审单处理完成" type="success" :closable="false" effect="dark">
				</el-alert>
			</div>

			<div @click="clickLookToggle(2)" v-show="pragrassShow">
				<el-alert title="<  批量审单处理中..." type="info" :closable="false" effect="dark">
				</el-alert>
			</div>
		</div>

		<!-- 批量发货弹框 -->
		<el-dialog :visible.sync="bathSendDialogVisible" width="800px" title="批量发货">
			<div>步骤一：点击下方，下载批量发货模板，下载时间筛选范围内的待发货订单</div>
			<p style="color:#409EFF;cursor: pointer;" @click="downLoadsend">下载批量发货模板</p>
			<p>步骤二：在下载的表格中，填写各订单物流公司，物流单号（如下示例图）；目前系统仅支持1单1物流</p>
			<img src="https://cdn.dkycn.cn/melyshop/image/20200309-6be4205a-8593-44fc-861a-6a0a9f79b6c2.png" alt="" style="width:760px;margin:10px 0">
			<p>步骤三：点击下方，导入文件，导入已完善物流公司和物流单号的表格</p>
			<form name="uploadForm" method="post">
				<input type="file" ref="upFiles" id="inputsId">
			</form>

			<div style="text-align: center;margin-top:30px">
				<el-button type="primary" @click="handleInportMember" :loading="sendorderLoading">确认上传</el-button>
			</div>
		</el-dialog>


		<!-- 批量发货进度条 -->
		<!-- 批量进行中 -->
		<el-dialog :title="batchsendTitle" :visible.sync="bathsendtaggelVisible" width="500px" :show-close="true" @closed="closeSendone">
			<p style="color:#333;font-size: 12px;text-align: center;" v-if="CompletesendCount!=TotalsendCount">已处理{{CompletesendCount}}个订单，剩余{{TotalsendCount-CompletesendCount}}个订单</p>
			<p style="color:#333;font-size: 12px;text-align: center;" v-if="CompletesendCount==TotalsendCount">成功发货{{SuccessendsCount}}个订单，失败{{TotalsendCount-SuccessendsCount}}个订单</p>
			<el-progress :text-inside="false" :stroke-width="24" :percentage="pragrassSendNums" status="success"></el-progress>
			<div style="text-align: center;margin-top:30px">
				<el-button @click="sendPrograssHide" v-if="CompletesendCount>0">收起进度</el-button>
				<el-button @click="downFailData" v-if="CompletesendCount>0&&CompletesendCount==TotalsendCount&&((TotalsendCount-SuccessendsCount)>0)">下载发货失败数据</el-button>
			</div>
		</el-dialog>

		<!-- 收起进度条的样式 -->
		<div class="prograss-box">
			<div @click="clicksendLookToggle(1)" v-show="sendPragrassSuccess">
				<el-alert title="<  批量发货完成" type="success" :closable="false" effect="dark">
				</el-alert>
			</div>

			<div @click="clicksendLookToggle(2)" v-show="sendPragrassShow">
				<el-alert title="<  批量发货中..." type="info" :closable="false" effect="dark">
				</el-alert>
			</div>
		</div>



		<!-- 查看物流 -->
		<el-dialog :visible.sync="logistdialogVisible" title="物流详情">
			<el-tabs type="border-card">
				<div class="express-info">
					<div class="info-item">
						<label class="label">物流公司</label>
						<span>{{expressInfo.ExpressValue}}</span>
					</div>
					<div class="info-item">
						<label class="label">物流单号</label>
						<span>{{expressInfo.ExpressNo}}</span>
					</div>
					<div class="info-item">
						<label class="label">物流跟踪</label>
						<div class="item-detail" v-if="expressInfo.ExpressTraceList  && expressInfo.ExpressTraceList.length">
							<div class="m-step" v-for="(j,index) in expressInfo.ExpressTraceList " :key="index" v-if="j&&j.AcceptTime">
								<div class="head">
									<div class="line"></div>
									<div class="icon"></div>
								</div>
								<div class="main">
									<div class="card">
										<div class="title">{{j.AcceptTime}}</div>
										<div class="content">{{j.AcceptStation}}</div>
									</div>
								</div>
							</div>
							<div v-else style="margin-top: 6px;">暂无信息</div>
						</div>
						<div v-else style="margin-top:23px">暂无跟踪到物流信息</div>
					</div>
				</div>
			</el-tabs>
			<!-- 	<div style="text-align: center;margin-top: 10px;">
				<el-button type="primary" @click="logistdialogVisible=false">关闭</el-button>
			</div> -->
		</el-dialog>

		<!-- 订单备注弹框 -->

		<el-dialog title="订单备注" :visible.sync="ordermarkDialogVisible" width="650px">
			<el-form :model="orderForm" ref="orderForm" :rules="rules">
				<el-form-item label="订单备注 :" label-width="120px">
					<el-input v-model="orderForm.orderMark" maxlength="200" type="textarea" style="width:300px;" clearable></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="ordermarkDialogVisible=false">关 闭</el-button>
				<el-button type="primary" @click="sureOrdermark('orderForm')" :loading="loading">确 认</el-button>
			</span>
		</el-dialog>

		<!-- 订单状态 待审单并且是退款中弹框 -->
		<div class="nocanDo">
			<el-dialog title="提示" :visible.sync="cantdoVisible" width="400px" @close="noCanClose">
				<div>订单中存在未处理完成的退款单，请先进行处理</div>
				<div style="text-align:center;margin-top: 15px;">
					<el-button type="primary" @click="canselFunction">去处理</el-button>
				</div>
			</el-dialog>
		</div>

		<!-- 修改金额弹框 -->
		<el-dialog title="修改订单金额" :visible.sync="editMoneyVisible" width="1100px">
			<div>订单原价（含运费）:{{oldPriceorder}}</div>
			<div class="editMoneyNotice">
				若要涨价，输入X元；若要降价，输入-X元；积分订单不支持修改订单金额；改价成功后，请联系客户刷新页面获取最新订单金额
			</div>

			<!-- 表格 -->
			<el-table :data="editMoneyTable" style="width: 100%" :span-method="objectSpanMethod" v-loading="editeMoneyLoading">
				<el-table-column prop="date" label="商品" width="230">
					<template slot-scope="scope">
						<div class="product-info">
							<!-- <img v-if="scope.row.ImgUrl" :src="imgUrl+scope.row.ImgUrl">
							<svg-icon v-else icon-class="noImgIcon" /> -->
							<div style="min-height: auto;">
								<div style="display:flex;justify-content:space-between;align-items:flex-start;">
									<div style="color: #f00;font-size: 12px;width:40px" v-if="scope.row.ProductType==1">[组合]</div>

									<div style="width:200px;">
										<pre style="white-space: pre-wrap;margin:0;font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif;">{{scope.row.ProductName}}</pre>
									</div>
								</div>
								<div style="color:#999">
									<span v-if="scope.row.SpecValue">{{scope.row.SpecValue}}</span>
									<span v-if="scope.row.SpecValue2">,{{scope.row.SpecValue2}}</span>
									<span v-if="!scope.row.SpecValue&&(!scope.row.SpecValue2)">默认规格</span>
								</div>
							</div>
						</div>
					</template>
				</el-table-column>
				<el-table-column prop="RemainCount" label="商品价格/件">
					<template slot-scope="scope">
						<div>{{scope.row.ProductPrice}}</div>
						<span style="color: #999;" v-if="scope.row.VipDiscount">会员折扣{{scope.row.VipDiscount}}%</span>
						<span style="color: #999;" v-if="scope.row.ActivityDiscountRate">限时折扣{{scope.row.ActivityDiscountRate}}%</span>
					</template>
				</el-table-column>
				<el-table-column prop="ProductCount" label="购买数量"></el-table-column>
				<el-table-column prop="DiscountMoney" label="优惠金额"></el-table-column>
				<el-table-column prop="RemainCount" label="小计(元)">
					<template slot-scope="scope">
						<div>{{(scope.row.ProductPrice*scope.row.ProductCount-scope.row.DiscountMoney).toFixed(2)}}</div>
					</template>
				</el-table-column>

				<el-table-column prop="name" label="涨价或减价(元)" width="110">
					<template slot-scope="scope" v-if="scope.$index==0">
						<el-input v-model="scope.row.monthMoney" placeholder="" style="width:80px;" size="small" @input.native="scope.row.monthMoney=
					                            scope.row.monthMoney.replace
					                            (scope.row.monthMoney,RestrictedMoney
					                            (scope.row.monthMoney));changePrice(scope.row)"
						 @change="materielExtraCostChange(scope.row.monthMoney)" />
					</template>
				</el-table-column>
				<!-- 自提订单不能修改运费 -->
				<el-table-column prop="name" label="运费" width="110">
					<template slot-scope="scope" v-if="scope.$index==0">
						<!-- 	<el-input v-model="priceFrights" :disabled="priceSendType==1?true:false" style="width:80px;" @input="changePrice(scope.row)"
						 size="small" onkeyup="(this.v=function(){this.value=this.value.replace(/[^\.\d]/g,'');this.value=this.value.match(/^\d*(\.?\d{0,2})/g)[0]||'';}).call(this);"></el-input>
						 -->
						<input :disabled="priceSendType==1?true:false" v-model="priceFrights" @input="changePrice(scope.row)" style="padding:0 10px;width:80px;height:32px;line-height: 32px;border:1px solid #ddd;border-radius: 5px;"
						 type="number" @keydown="handleInput3">
					</template>
				</el-table-column>

				<el-table-column prop="payPrice" label="支付金额">
					<template slot-scope="scope" v-if="scope.$index==0">
						<div>{{scope.row.payPrice}}</div>
					</template>
				</el-table-column>
			</el-table>

			<div style="text-align: center;margin-top: 20px;">
				<el-button @click="editMoneyVisible=false" style="width:120px;margin-right:15px">关闭</el-button>
				<el-button type="primary" @click="sureEditMoney" style="width:120px" :loading="loading">确认修改</el-button>
			</div>

		</el-dialog>

		<!-- 修改配送方式弹框 -->
		<el-dialog title="修改配送方式" :visible.sync="eiditeSendsgVisible" width="800px">
			<el-checkbox v-model="SendsChecked">
				确认将配送方式修改为 <span style="color:red">快递发货</span>
			</el-checkbox>

			<!-- 选中配置后 -->
			<el-form :model="sendRuleForm" ref="sendRuleForm" label-width="100px" class="demo-ruleForm" :rules="rules">
				<div v-if="SendsChecked">
					<p style="color:#666">请完善收货人信息：</p>
					<el-form-item label="收货人姓名" prop="Name">
						<el-input placeholder="请输入内容" maxlength="15" v-model="sendRuleForm.Name" clearable>
						</el-input>
					</el-form-item>
					<el-form-item label="收货人手机" prop="phone">
						<el-input placeholder="请输入手机号" v-model="sendRuleForm.phone" clearable maxlength="11" onkeyup="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}"
						 onafterpaste="if(this.value.length==1){this.value=this.value.replace(/[^1-9]/g,'')}else{this.value=this.value.replace(/\D/g,'')}">
						</el-input>
					</el-form-item>
					<el-form-item label="收货地址" prop="AddressArr">
						<el-cascader style="width: 300px;" :props="props" :options="dataList" clearable @change="handleAddressChange"
						 v-model="sendRuleForm.AddressArr" />
					</el-form-item>

					<el-form-item style="box-sizing: border-box" prop="Address">
						<el-col :span="14">
							<el-input type="textarea" v-model="sendRuleForm.Address" rows="4" placeholder="请输入详细地址"></el-input>
						</el-col>

					</el-form-item>

				</div>

				<div style="text-align: center;margin-top: 50px;">
					<el-button @click="eiditeSendsgVisible=false" style="width:120px;margin-right:15px">关闭</el-button>
					<el-button type="primary" @click="sureEditSends('sendRuleForm')" style="width:120px" :loading="loading">确认修改</el-button>
				</div>
			</el-form>
		</el-dialog>



	</div>
</template>

<script>
	// 订单状态 待付款:1   待审单：2 待发货：3  已发货：4 已完成：5  已关闭：8
	import {
		// orderIndex,
		orderIndexinit,
		orderIndexClose,
		orderCheck,
		orderbatchCheck,
		ordergetProgress,
		ordereditReceive,
		editendTypesave,
		editPriceinit,
		editPricesave,
		
	} from '@/api/goods'
	import {
		supplierOrderList,
		orderIndexStar,
		orderSend,
		orderlookExpress,
		orderRemark,
		orderimportGetprogress
	} from '@/api/TurnTomySelf'
	import config from '@/config/index'
	import buttonPermissions from '@/components/buttonPermissions';
	import axios from 'axios'
	export default {
		components: {
			buttonPermissions
		},
		data() {
			var checkPhone = (rule, value, callback) => {
				if (!value) {
					return callback(new Error('手机号不能为空'));
				} else {
					const reg = /^1[3|4|5|6|7|8|9][0-9]\d{8}$/
					console.log(reg.test(value));
					if (reg.test(value)) {
						callback();
					} else {
						return callback(new Error('请输入正确的手机号'));
					}
				}
			};
			return {
				sendorderLoading: false,
				sendFaildata: 0,
				sendPragrassSuccess: false,
				sendPragrassShow: false,
				batchsendTitle: '批量发货中...',
				bathsendtaggelVisible: false,
				CompletesendCount: 0,
				SuccessendsCount: 0,
				TotalsendCount: 0,
				pragrassSendNums: 0,
				sendTimers: null,
				bathSendDialogVisible: false,
				oldPriceorder: '',
				dataList: [],
				sendRuleForm: {
					Name: "",
					phone: "",
					AddressArr: [],
					Address: ''
				},
				props: {
					label: 'name',
					value: 'id',
					children: 'child'
				},
				eiditeSendsgVisible: false,
				SendsChecked: false,
				editeMoneyLoading: false,
				editMoneyTable: [{
					ProductName: "测试商品"
				}],
				editMoneyVisible: false,
				expendsData: [],
				cantdoVisible: false,
				baseAPI: config.BASE_URL,
				SuccessCount: 0,
				pragrassNums: 0,
				pragrassSuccess: false,
				pragrassShow: false,
				CompleteCount: 0,
				TotalCount: 0,
				pickerOptions: {
					disabledDate(time) {
						let curDate = (new Date()).getTime();
						let three = 90 * 24 * 3600 * 1000;
						let threeMonths = curDate - three;
						return time.getTime() > Date.now() || time.getTime() < threeMonths;
					}
				},
				startNum: null,
				searchType: 0,
				goodsSelect: [{
						id: 0,
						value: '订单号'
					},
					{
						id: 1,
						value: '外部交易单号'
					},
					{
						id: 2,
						value: '收货人信息'
					},
					// {
					// 	id: 3,
					// 	value: '店员/推手'
					// },
					{
						id: 4,
						value: '商品名称或编码'
					},
				],
				searchKey: '',
				placeholders: '订单编号',
				starTime: '',
				endTime: '',
				timeType: 0,
				timeTypeList: [{
						id: 0,
						value: '下单时间'
					},
					{
						id: 1,
						value: '支付时间'
					},
					{
						id: 2,
						value: '发货时间'
					},
					{
						id: 3,
						value: '完成时间'
					}
				],
				orderStage: [],
				orderStageList: [],
				orderType: null,
				orderTypeList: [],
				orderDoor: null,
				orderDoorList: [],
				sendType: null,
				sendTypeList: [],
				sendDoor: null,
				sendDoorList: [],
				RefundInfo: null,
				RefundInfoList: [{
						id: 1,
						value: '退款中'
					},
					{
						id: 2,
						value: '退款结束'
					}
				],
				isStart: null,
				isStartList: [{
						id: 1,
						value: '是'
					},
					{
						id: 0,
						value: '否'
					}
				],
				groupData: [],
				loading: false,
				currentPage: 1,
				pageSize: 20,
				Total: 0,
				SendDialogVisible: false,
				sendForm: {
					ExpressId: '',
					ExpressNo: ''
				},
				formate: {
					closeReason: ''
				},
				ExpressList: [], //所有的物流公司
				rules: {
					ExpressId: [{
						required: true,
						message: '请选择物流公司',
						trigger: 'change'
					}],
					ExpressNo: [{
						required: true,
						message: '请输入物流单号',
						trigger: 'blur'
					}],
					closeReason: [{
						required: true,
						message: '请选择订单关闭理由',
						trigger: 'change'
					}],
					orderMark: [{
							required: true,
							message: '请填写订单备注',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 200,
							message: '长度在 1 到 200 个字符',
							trigger: 'blur'
						}
					],
					Name: [{
							required: true,
							message: '请输入收货人姓名',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 15,
							message: '长度在 1 到 15 个字符',
							trigger: 'blur'
						}
					],
					phone: [{
						required: true,
						validator: checkPhone,
						trigger: 'blur'
					}],
					AddressArr: [{
						required: true,
						message: '请选择收货地址',
						trigger: 'change'
					}],
					Address: [{
							required: true,
							message: '请输入详细地址',
							trigger: 'blur'
						},
						{
							min: 1,
							max: 100,
							message: '长度在 1 到 100 个字符',
							trigger: 'blur'
						}
					],
				},
				noSendDialogVisible: false,
				chenckDialogVisible: false,
				closeorderDialogVisible: false,
				closeReason: '',
				closeReasonList: ['无法联系上买家', '买家误怕或重拍了', '买家无诚意完成交易'],
				bathDialogVisible: false,
				bathtaggelVisible: false,
				percentageVal: 0,
				batchTitle: '批量审单处理中...',
				logistdialogVisible: false,
				expressInfo: {},
				orderForm: {
					orderMark: ''
				},

				ordermarkDialogVisible: false,
				startVisible: false,
				startCount: 1,
				closeOrderData: {},
				sendFunctionData: {},
				orderData: {},
				timers: null,
				IsAllowStop: true,
				ActivityType: null,
				ActivityId: null,
				CouponId: null,
				CustomerId: null,
				OrderBy: '',
				IsAsc: '',
				goOrderdatas: {},
				sendsOrdernums: '',
				editPriceOrder: '',
				priceFrights: '',
				priceSendType: '',
				exportUrl: config.EXPORT_URL,
				goUrls: config.GO_URL,
				ErrorMessageKey: ''

			}
		},
		beforeMount() {
			let date = new Date();
			let year = date.getFullYear();
			let month = (date.getMonth() + 1) > 9 ? (date.getMonth() + 1) : ('0' + (date.getMonth() + 1));
			let day = date.getDate() > 9 ? date.getDate() : ('0' + date.getDate());
			let todays = year + '-' + month + '-' + day + ' ' + '23:59:59'
			let todayTimes = new Date(todays.replace(/-/g, "/")).getTime();
			this.starTime = this.$route.query.starTime ? this.$route.query.starTime : '';
			this.endTime = this.$route.query.endTime ? this.$route.query.endTime : ''

			// if (routerStart || routerEnd) {
			// 	let start = new Date(routerStart.replace(/-/g, "/")).getTime();
			// 	let end = new Date(routerEnd.replace(/-/g, "/")).getTime();
			// 	if (end - start > 90 * 24 * 60 * 60 * 1000) {
			// 		let dd = new Date(routerStart);
			// 		dd.setDate(dd.getDate() + 90); //获取AddDayCount天后的日期
			// 		let y = dd.getFullYear();
			// 		let m = (dd.getMonth() + 1) < 10 ? "0" + (dd.getMonth() + 1) : (dd.getMonth() + 1); //获取当前月份的日期，不足10补0
			// 		let d = dd.getDate() < 10 ? "0" + dd.getDate() : dd.getDate(); //获取当前几号，不足10补0
			// 		let newEndtime = y + '-' + m + '-' + d + ' ' + '23:59:59'
			// 		let threeTimes = new Date(newEndtime.replace(/-/g, "/")).getTime();
			// 		if (threeTimes > todayTimes) {
			// 			this.starTime = routerStart
			// 			this.endTime = todays;
			// 		} else {
			// 			this.starTime = routerStart
			// 			this.endTime = newEndtime
			// 		}
			// 	} else {
			// 		if (end > todayTimes) {
			// 			this.starTime = routerStart
			// 			this.endTime = todays;
			// 		} else {
			// 			this.starTime = routerStart
			// 			this.endTime = routerEnd
			// 		}
			// 	}
			// } else {
			// 	this.starTime = year + '-' + month + '-' + '01' + ' ' + '00:00:00';
			// 	this.endTime = year + '-' + month + '-' + day + ' ' + '23:59:59';
			// }


			this.ActivityType = this.$route.query.ActivityType ? this.$route.query.ActivityType : '';
			this.ActivityId = this.$route.query.ActivityId ? this.$route.query.ActivityId : '';
			this.CouponId = this.$route.query.CouponId ? this.$route.query.CouponId : '';
			this.CustomerId = this.$route.query.CustomerId ? this.$route.query.CustomerId : '';
			this.searchType = this.$route.query.searchType ? this.$route.query.searchType : this.searchType
			this.searchKey = this.$route.query.OrderNo ? this.$route.query.OrderNo : ''

			this.orderIndexinit();
			this.getList()
			this.getPrograss();
			this.getDataList()
			this.startProgress()

		},
		computed: {
			minOptions: function() {
				let limitTime = this.starTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return time < new Date(new Date(limitTime).toLocaleDateString())
						}
					},
				}
			},
			//日期选择器限制选择
			maxOptions: function() {
				let limitTime = this.endTime
				let d = new Date();
				let day = d.getDate();
				let month = d.getMonth() + 1;
				let year = d.getFullYear();
				let currentDate = year + '-' + month + '-' + day + ' ' + '00:00:00'
				return {
					disabledDate(time) {
						if (limitTime) {
							return (
								time > new Date(new Date(limitTime).toLocaleDateString())
							)
						}
					}
				}
			}
		},
		methods: {
			// 开始获取进度
			startProgress() {
				this.sendTimers = setInterval(() => {
					this.getsendPrograss().then(() => {
						if (this.TotalsendCount == 0) {
							clearInterval(this.sendTimers)
						}
						if (this.TotalsendCount > 0 && (this.TotalsendCount == this.CompletesendCount)) {
							clearInterval(this.sendTimers)
							this.batchsendTitle = '批量发货处理完成'
						} else if (this.TotalsendCount > 0 && (this.TotalsendCount != this.CompletesendCount)) {
							this.batchsendTitle = '批量发货处理中...'
						}
					})
				}, 1000)
				setTimeout(() => {
					this.sendPrograssHide();
					this.loading = false
				}, 2000)

			},
			// 批量发货
			bathsendOrder() {
				console.log(this.$refs.upFiles, 999)
				this.$nextTick(() => {
					if (this.$refs.upFiles) {
						this.$refs.upFiles.value = '';
					}

				});
				this.bathSendDialogVisible = true
			},
			// 确认上传
			async handleInportMember() {
				let that = this;
				const loading = that.$loading({
					lock: true,
					text: 'Loading',
					spinner: 'el-icon-loading',
					// background: 'rgba(0, 0, 0, 0.7)'
				});

				try {
					setTimeout(() => {
						var formData = new FormData();
						formData.append('file', that.$refs['upFiles'].files[0])
						$.ajax({
							// /pc/supplier-order/order-send-export
							url: this.exportUrl + '/pc/supplier-order/order-import-send',
							timeout: 20000, //超时时间设置，单位毫秒
							type: 'POST',
							data: formData,
							async: false,
							cache: false,
							contentType: false,
							xhrFields: {
								withCredentials: true
							},
							processData: false,
							success: function(returndata) {
								if (returndata.IsSuccess) {
									that.$message({
										message: '上传成功',
										type: 'success'
									});
									that.bathSendDialogVisible = false;
									that.sendTimers = setInterval(() => {
										that.getsendPrograss().then(() => {
											if (that.TotalsendCount == 0) {
												clearInterval(that.sendTimers)
											}
											if (that.TotalsendCount > 0 && (that.TotalsendCount == that.CompletesendCount)) {
												clearInterval(that.sendTimers)
												that.batchsendTitle = '批量发货处理完成'
											} else if (that.TotalsendCount > 0 && (that.TotalsendCount != that.CompletesendCount)) {
												that.batchsendTitle = '批量发货处理中...'
											}
										})

									}, 1000)
									setTimeout(() => {
										that.bathsendtaggelVisible = true;
									}, 1000)
									loading.close();
								} else {
									loading.close();
									that.$message({
										message: returndata.Message,
										type: 'error'
									});
								}
							},
							complete: function(XMLHttpRequest, status) { //请求完成后最终执行参数
								if (status == 'timeout') { //超时,status还有success,error等值的情况
									ajaxTimeoutTest.abort();
									alert("超时");
								}
							},
							error: function(returndata) {
								console.log(returndata, '失败 的信息')
								loading.close();
								that.$message({
									message: returndata.Message,
									type: 'error'
								});
								// alert("文件上传失败");
							}
						});
					}, 500)


				} catch (error) {
					loading.close();
					console.log(error);
				} finally {
					that.getList()
					// this.getRole();
					// that.exportloading = false;
				}

			},
			// 下载模板
			downLoadsend() {
				let url = this.exportUrl + '/pc/supplier-order/order-send-export?StartTime=' + this.starTime + '&EndTime=' + this.endTime +
					'&TimeType=' + this.timeType
				console.log(this.goUrls)
				window.open(url)
			},
			// 下载失败数据
			downFailData() {
				let url = this.exportUrl + '/pc/supplier-order/order-import-send-download-error?ErrorMessageKey=' + this.ErrorMessageKey
				console.log(url,'下载失败的网址')
				window.open(url)
			},
			// 关闭弹框			
			closeSendone() {
				this.sendPrograssHide()
			},
			// 获取发货进度条信息
			async getsendPrograss() {
				try {
					let result = await orderimportGetprogress()
					this.ErrorMessageKey = result.Result.ErrorMessageKey
					this.TotalsendCount = result.Result.TotalCount;
					this.CompletesendCount = result.Result.CompleteCount
					this.SuccessendsCount = result.Result.SuccessCount
					this.pragrassSendNums = this.TotalsendCount ? ((this.CompletesendCount / this.TotalsendCount) * 100) : 0

				} catch (e) {
					console.log(e)
				} finally {}
			},
			// 查看订单发货弹框
			clicksendLookToggle(type) {
				if (type == 1) {
					this.batchsendTitle = '批量发货处理完成'
				} else {
					this.batchsendTitle = '批量发货中...'
				}
				this.bathsendtaggelVisible = true;
			},
			// 订单发货 收起进度
			sendPrograssHide() {
				if (this.TotalsendCount > 0 && (this.TotalsendCount == this.CompletesendCount)) {
					this.sendPragrassSuccess = true
					this.sendPragrassShow = false
				} else if (this.TotalsendCount > 0 && (this.TotalsendCount != this.CompletesendCount)) {
					this.sendPragrassShow = true
					this.sendPragrassSuccess = false
				}
				this.bathsendtaggelVisible = false
			},

			handleInput2(e) {
				// 通过正则过滤小数点后两位
				e.target.value = (e.target.value.match(/^\d*(\.?\d{0,0})/g)[0]) || null

			},
			handleInput3(e) {
				// 通过正则过滤小数点后两位
				e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
			},
			//获取地址数据
			getDataList() {
				var that = this
				axios.get('http://cdn.dkycn.cn/json/citylist-include.json', {})
					.then(function(response) {
						that.dataList = response.data
					})
					.catch(function(error) {})
			},
			handleAddressChange() {
				this.sendRuleForm.ProvinceId = this.sendRuleForm.AddressArr[0]
				this.sendRuleForm.CityId = this.sendRuleForm.AddressArr[1]
				this.sendRuleForm.AreadId = this.sendRuleForm.AddressArr[2]
				this.dataList.map(item => {
					if (item.id == this.sendRuleForm.ProvinceId) {
						this.sendRuleForm.Province = item.name
						item.child.map(items => {
							if (items.id == this.sendRuleForm.CityId) {
								this.sendRuleForm.City = items.name
								items.child.map(lastitems => {
									if (lastitems.id == this.sendRuleForm.AreadId) {
										this.sendRuleForm.Area = lastitems.name
									}
								})
							}
						})
					}
				})

				this.center = {
					lng: 0,
					lat: 0
				}

			},
			objectSpanMethod({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (columnIndex === 5 || columnIndex === 6 || columnIndex === 7) {
					return {
						rowspan: 1,
						colspan: 1
					};
				}

			},
			// 改变价格
			changePrice(record) {

				this.editMoneyTable.map(item => {
					item.payPrice = (Number(this.sumPayprice(record)) + Number(this.priceFrights ? this.priceFrights : 0) + Number(
						item.monthMoney ? item.monthMoney : 0)).toFixed(2)
					return item
				})
			},
			// 计算支付金额
			sumPayprice(record) {
				let allMoney = 0
				this.editMoneyTable.map(item => {
					allMoney = Number(allMoney) + (item.ProductCount * item.ProductPrice - item.DiscountMoney)
				})

				return allMoney
			},

			// 订单改价初始化
			async priceInt() {
				try {
					this.editeMoneyLoading = true
					let result = await editPriceinit({
						OrderNo: this.editPriceOrder
					})
					this.oldPriceorder = result.Result.OriginalOrderMoney
					this.priceFrights = result.Result.FreightMoney
					this.editMoneyTable = []
					let allMoney = 0
					result.Result.DetailList.map(item => {
						allMoney = Number(allMoney) + (item.ProductCount * item.ProductPrice - item.DiscountMoney)
						let obj = {
							ProductName: item.ProductName,
							ProductCount: item.ProductCount,
							SpecValue: item.SpecValue,
							SpecValue2: item.SpecValue2,
							ActivityDiscountRate: item.ActivityDiscountRate,
							VipDiscount: item.VipDiscount,
							ActivityMarkdownGiftMoney: item.ActivityMarkdownGiftMoney,
							ActivityCouponMoney: item.ActivityCouponMoney,
							ActivityBaleMoney: item.ActivityBaleMoney,
							ActivityPointExchangeMoney: item.ActivityPointExchangeMoney,
							DiscountMoney: item.DiscountMoney,
							ProductPrice: item.ProductPrice,
							monthMoney: result.Result.ModifyMoney ? result.Result.ModifyMoney : (0.00).toFixed(2),
							payPrice: (allMoney + Number(this.priceFrights ? this.priceFrights :
								0) + Number(result.Result.ModifyMoney ? result.Result.ModifyMoney : 0)).toFixed(2)
						}
						this.editMoneyTable.push(obj)
					})

					this.editMoneyTable.map(item => {
						item.payPrice = (allMoney + Number(this.priceFrights ? this.priceFrights :
							0) + Number(result.Result.ModifyMoney ? result.Result.ModifyMoney : 0)).toFixed(2)
					})

				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.editeMoneyLoading = false
				}

			},
			// 修改金额
			editeMoneyFun(record) {
				this.editPriceOrder = record.OrderNo
				this.priceSendType = record.SendType
				this.priceInt()
				this.editMoneyVisible = true
			},
			// 
			// 确认修改金额
			async sureEditMoney() {
				try {
					this.loading = true
					if (!this.priceFrights && (this.priceFrights !== 0)) {
						this.$message({
							showClose: true,
							type: 'error',
							message: '请设置正确的运费!'
						});
						return
					}

					let data = {
						OrderNo: this.editPriceOrder,
						FreightMoney: this.priceFrights,
						ModifyMoney: this.editMoneyTable[0].monthMoney
					}


					let result = await editPricesave(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '订单金额修改成功!'
						});
						this.getList()
						this.editMoneyVisible = false
					}


				} catch (e) {
					//TODO handle the exception
					console.log(e)
				} finally {
					this.loading = false
				}

			},

			// 修改配送方式
			editeSendFun(record) {
				this.sendsOrdernums = record.OrderNo
				this.sendRuleForm.Name = record.ReceiveName;
				this.sendRuleForm.phone = record.ReceivePhone;
				this.sendRuleForm.AddressArr = [],
					this.sendRuleForm.Address = ''
				this.SendsChecked = false
				this.eiditeSendsgVisible = true;

			},
			// 确认修改配送方式
			sureEditSends(formName) {

				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						try {
							this.loading = true

							if (!this.SendsChecked) {
								this.$message({
									showClose: true,
									type: 'error',
									message: '请先确认修改配送方式!'
								});
								return
							}

							let data = {
								OrderNo: this.sendsOrdernums,
								ReceiveName: this.sendRuleForm.Name,
								ReceivePhone: this.sendRuleForm.phone,
								ReceiveProvince: this.sendRuleForm.Province,
								ReceiveCity: this.sendRuleForm.City,
								ReceiveArea: this.sendRuleForm.Area,
								ReceiveAddress: this.sendRuleForm.Address,
								ProvinceId: this.sendRuleForm.ProvinceId,
								CityId: this.sendRuleForm.CityId,
								AreaId: this.sendRuleForm.AreadId
							}
							let result = await editendTypesave(data)
							console.log(result)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.getList()
								this.eiditeSendsgVisible = false
							}

						} catch (e) {
							//TODO handle the exception
							console.log(e)
						} finally {
							this.loading = false
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});


			},

			plusOrMinus(values) {
				let newValue;
				if (!(/[^0-9.-]/g.test(values))) {
					newValue = values.replace(/[^\-\d.]/g, '').replace(/\-{2,}/g, '-').replace(/\-{2,}/g, '-').replace(/^\./g, '')
						.replace(/\.{2,}/g, '.')
						.replace('.', '$#$')
						.replace(/\./g, '')
						.replace('$#$', '.');
					if (newValue.toString().indexOf('.') > 0 && Number(newValue.toString().split('.')[1].length) > 2) {
						newValue = parseInt(parseFloat(newValue) * 100) / 100;
					}
					if ((newValue.toString().split('-').length - 1) > 1) {
						newValue = parseFloat(newValue) || '';
					}
					if ((newValue.toString().split('-').length) > 1 && newValue.toString().split('-')[0].length > 0) {
						newValue = parseFloat(newValue) || '';
					}
					if (newValue.toString().length > 1 && (newValue.toString().charAt(0) === '0' || (newValue.toString().length > 2 &&
							newValue.toString().charAt(0) === '-' && newValue.toString().charAt(1) === '0' && newValue.toString().charAt(2) !==
							'.')) && newValue.toString().indexOf('.') < 1) {
						newValue = parseFloat(newValue) || '';
					}
					// 判断整数位最多为9位
					if (newValue.toString().indexOf('.') > 0 && Number(newValue.toString().split('.')[0].length) > 9) {
						newValue = `${newValue.toString().substring(0, 9)}.${newValue.toString().split('.')[1]}`;
					} else if (newValue.toString().indexOf('.') < 0 && Number(newValue.toString().split('.')[0].length) > 9) {
						newValue = newValue.toString().substring(0, 9);
					}
				} else {
					newValue = values.replace(/[^0-9.-]/g, '');
				}
				return newValue;
			},
			RestrictedMoney(values) {
				return this.plusOrMinus(values.toString());
			},
			// 结合change事件对失去焦点进行判断，防止输入一些无效值
			materielExtraCostChange(item) {
				// 防止删除为空
				if (!item) {
					item = '0.00';
				}
				// 一些错误金额输入的判断
				if (item.toString().indexOf('.') > 0 && Number(item.toString().split('.')[1].length) < 1) {
					item = item.toString().split('.')[0];
				}
				// 一些错误金额输入的判断
				if (!item || item === '-' || item === '-0') {
					item = '0.00';
					return;
				}
				item = parseFloat(item).toFixed(2);
				// this.educationReserve.monthMoney = item;
			},
			setClassName({
				row,
				index
			}) {
				// console.log(row, '展开数据99')
				// 通过自己的逻辑返回一个class或者空
				let length = 0
				if (row && (row.CustomerRemark || row.SystemRemark)) {
					length = 1
				}
				if (!length) {
					return 'unexpand'
				} else {
					return ''
				}
			},
			CloseprograssDia() {

			},
			//进入客户详情页
			customerFun(record) {
				this.$router.push({
					path: '/Customer/CustomerDetail',
					query: {
						Id: record.CustomerId
					}
				});
				console.log(record, 789)
			},
			// 设置关键字
			rowKeys(row) {
				return row.OrderNo
			},
			// 去处理订单
			canselFunction() {
				this.$router.push({
					path: '/order/supplyRefund/index',
					query: {
						OrderNo: this.goOrderdatas.OrderNo ? this.goOrderdatas.OrderNo : 0
					}
				});
			},
			//退款中 订单发货
			sendFunctionNo(record) {
				this.goOrderdatas = record
				this.cantdoVisible = true;
			},
			// 关闭弹框
			noCanClose() {
				this.getList()
			},
			// 关键字搜索
			keyChange() {
				if (this.searchType == 0) {
					this.placeholders = "请输入订单编号"
				} else if (this.searchType == 1) {
					this.placeholders = "请输入外部交易单号"
				} else if (this.searchType == 2) {
					this.placeholders = "请输入收货人姓名、手机号"
				} else if (this.searchType == 3) {
					this.placeholders = "请输入店员/推手姓名、手机号"
				} else if (this.searchType == 4) {
					this.placeholders = "请输入商品名称或编码"
				}

			},

			// 列表初始化数据
			async orderIndexinit() {
				try {
					let result = await orderIndexinit()
					this.orderDoorList = result.Result.ShopSelectList;
					this.orderStageList = result.Result.OrderStateSelectList;
					this.orderTypeList = result.Result.OrderTypeSelectList;
					this.sendTypeList = result.Result.SendTypeSelectList;
					this.ExpressList = result.Result.ExpressList;
					let obj = {
						Value: -1,
						Text: '全部'
					}
					this.orderStageList.unshift(obj)
				} catch (e) {
					console.log(e)
				} finally {

				}

			},

			// 获取商品列表
			async getList() {
				try {
					this.loading = true
					let data = {
						Keywords: this.searchKey, // 搜索关键字
						KeywordsType: this.searchType,
						StartTime: this.starTime,
						EndTime: this.endTime,
						TimeType: this.timeType,
						OrderStateList: this.orderStage.includes(-1) ? [] : this.orderStage,
						OrderType: this.orderType,
						IsStar: this.isStart,
						RefundInfo: this.RefundInfo,
						Skip: (this.currentPage - 1) * this.pageSize, // 略过数据
						Take: this.pageSize, // 取的数据
						OrderBy: this.OrderBy,
						IsAsc: this.IsAsc,
					}
					let result = await supplierOrderList(data);
					this.Total = result.Result.Total;
					this.groupData = result.Result.Results || [];
					this.groupData.map(item => {
						item.startVisible = false
						return item
					})


				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false
				}

			},


			goodsFilter() {
				this.currentPage = 1;
				this.getList()
			},
			// 订单发货
			sendFunction(record) {
				this.sendFunctionData = record;
				if (record.SendType == 0) {
					this.SendDialogVisible = true;
				} else {
					this.noSendDialogVisible = true
				}
			},
			// 审单
			async checkOrders(record) {
				this.goOrderdatas = record
				if (record.RefundInfo == "退款中") {
					this.cantdoVisible = true
					return
				}
				try {
					this.loading = true;
					let result = await orderCheck({
						OrderNo: record.OrderNo
					})
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
					}


				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false;
					this.getList()
				}

			},
			// 关闭订单
			closeOrder(record) {
				this.closeOrderData = record
				this.formate.closeReason = ''
				this.closeorderDialogVisible = true;
			},

			// 确认关闭订单
			submitclose(formName) {

				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.loading = true;
						try {
							let data = {
								OrderNo: this.closeOrderData.OrderNo,
								CancelReason: this.formate.closeReason
							}
							let result = await orderIndexClose(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.closeorderDialogVisible = false;
							}
						} catch (error) {
							console.log(error)
						} finally {
							this.loading = false;
							this.goodsFilter();
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});


			},
			// 批量审单
			bathOrrders() {
				this.bathDialogVisible = true
			},
			// 关闭订单弹框
			closeorderFun() {
				this.formate.closeReason = ''
				this.closeorderDialogVisible = false;
			},
			// 开始批量审单
			async startBathorder() {
				try {
					this.loading = true;
					let result = await orderbatchCheck();
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
						this.bathDialogVisible = false;

						this.timers = setInterval(() => {
							this.getPrograss()
							if (this.TotalCount == this.CompleteCount) {
								clearInterval(this.timers)
								this.batchTitle = '批量审单处理完成'
							} else {
								this.batchTitle = '批量审单处理中...'
							}
						}, 1000)

						setTimeout(() => {
							this.bathtaggelVisible = true;
						}, 600)

					}


				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false;
				}

			},

			// 收起进度
			prograssHide() {
				if (this.TotalCount == this.CompleteCount) {
					this.pragrassSuccess = true
				} else {
					this.pragrassShow = true
				}
				this.bathtaggelVisible = false
			},

			// 获取进度条信息
			async getPrograss() {
				try {
					let result = await ordergetProgress()
					this.TotalCount = result.Result.TotalCount;
					this.CompleteCount = result.Result.CompleteCount
					this.SuccessCount = result.Result.SuccessCount
					this.pragrassNums = this.TotalCount ? ((this.CompleteCount / this.TotalCount) * 100) : 0
					this.IsAllowStop = result.Result.IsAllowStop
					if (this.TotalCount > 0 && (this.TotalCount != this.CompleteCount)) {
						this.pragrassShow = true;
					}

				} catch (e) {
					console.log(e)
				} finally {

				}

			},
			// 中断审单
			craptaggle() {

			},
			// 查看订单审单弹框
			clickLookToggle(type) {
				if (type == 1) {
					this.batchTitle = '批量审单处理完成'
				} else {
					this.batchTitle = '批量审单处理中...'
				}
				this.bathtaggelVisible = true;
			},
			// 订单备注
			orderMark(record) {
				this.orderData = record;
				this.orderForm.orderMark = record.SystemRemark
				this.ordermarkDialogVisible = true;

			},
			// 确认订单备注
			sureOrdermark(formName) {
				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.loading = true;
						try {
							let data = {
								OrderNo: this.orderData.OrderNo,
								SystemRemark: this.orderForm.orderMark
							}
							let result = await orderRemark(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.ordermarkDialogVisible = false;
								this.getList()
							}
						} catch (error) {
							console.log(error)
						} finally {
							this.loading = false;
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});

			},
			// 加星
			addStar(record) {

			},

			// 改变星星
			async rateChange(record) {

				try {
					let data = {
						OrderNo: record.OrderNo,
						Star: record.Star
					}
					let result = await orderIndexStar(data)
					if (result.IsSuccess) {
						this.$message({
							showClose: true,
							type: 'success',
							message: '操作成功!'
						});
					}

				} catch (e) {
					console.log(e)
				} finally {
					this.goodsFilter();
				}


			},

			// 查看物流
			async lookLogest(record) {
				this.logistdialogVisible = true;
				try {
					let result = await orderlookExpress({
						OrderNo: record.OrderNo
					})
					this.expressInfo = result.Result

				} catch (e) {
					console.log(e)
				} finally {

				}

			},
			// 查看订单详情
			lookOrderDetails(record) {
				let OrderNoId = record.OrderNo ? record.OrderNo : 0
				let headsUrls = process.env.NODE_ENV == 'production' ? 'index.html#' : ''
				let url = this.goUrls + headsUrls + '/order/distributeOrder/distributeDetail?Id=' + OrderNoId

				window.open(url)

				// this.$router.push({
				// 	path: '/order/orderDetailpage',
				// 	query: {
				// 		Id: record.OrderNo ? record.OrderNo : 0
				// 	}
				// });
			},

			// 切换显示条数
			handleSizeChange(val) {
				// console.log(`每页 ${val} 条`);
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				console.log(`当前页: ${val}`);
				this.currentPage = val;
				this.getList();
			},

			// 导出
			async exportFun() {
				this.loading = true;
				try {
					let url = this.exportUrl + '/pc/supplier-order/export?' +
						'&Keywords=' + (this.searchKey ? encodeURIComponent(this.searchKey) : '') +
						'&KeywordsType=' + (this.searchType ? this.searchType : '') +
						'&StartTime=' + (this.starTime ? this.starTime : '') +
						'&EndTime=' + (this.endTime ? this.endTime : '') +
						'&TimeType=' + (this.timeType ? this.timeType : '') +
						'&OrderStateList=' + (this.orderStage.includes(-1) ? [] : this.orderStage) +
						'&OrderType=' + (this.orderType ? this.orderType : '') +
						'&IsStar=' + (this.isStart ? this.isStart : '') +
						'&RefundInfo=' + (this.RefundInfo ? this.RefundInfo : '') +
						'&OrderBy=' + this.OrderBy +
						'&IsAsc=' + this.IsAsc
					window.open(url);
					console.log('url', url)
				} catch (error) {
					console.log(error);
				} finally {
					this.loading = false;
				}

			},

			//取消
			closeDeliver() {
				this.sendForm.ExpressId = ''
				this.sendForm.ExpressNo = ''
				this.SendDialogVisible = false
			},

			submitSends(formName) {

				this.$refs[formName].validate(async (valid) => {
					if (valid) {
						this.loading = true;
						try {

							let data = {
								OrderNo: this.sendFunctionData.OrderNo,
								Express: this.sendForm.ExpressId,
								ExpressNo: this.sendForm.ExpressNo
							}
							let result = await orderSend(data)
							if (result.IsSuccess) {
								this.$message({
									showClose: true,
									type: 'success',
									message: '操作成功!'
								});
								this.closeDeliver()

							}
						} catch (error) {
							console.log(error)
						} finally {
							this.getList()
							this.loading = false;
						}
					} else {
						console.log('error submit!!');
						return false;
					}
				});


			},


		}
	}
</script>

<style lang="less">
	.ordermange {
		width: 100%;
		background: #fff;
		padding-top: 30px;
		// padding: 30px;

		.nocanDo {
			.el-dialog__body {
				padding: 10px 20px 20px 20px !important;
			}
		}

		input[type=number]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}


		.classBottoms {
			margin-bottom: 10px;
		}

		.label {
			text-align: right;
			display: inline-block;
			width: 130px;
		}

		.pointers {
			cursor: pointer;
		}

		.sendClass {
			color: #333;
			font-size: 14px;
			padding: 0 5px;
			line-height: 25px;
		}

		.prograss-box {
			position: fixed;
			right: 0;
			bottom: 60px;
			z-index: 100;
			background: #DCDFE6;
		}

		.el-alert {
			border-radius: 0;
		}

		/deep/.unexpand .el-table__expand-column .cell {
			display: none;
		}

		/deep/.unexpand .el-table__expanded-cell {
			display: none;
		}

		/deep/.el-table__expanded-cell[class*=cell] {
			padding: 0 0 0 50px;
		}

		/deep/.el-table__expanded-cell {
			padding-left: 50px;
		}

		.bath-checkorders {
			.el-dialog__header {
				padding: 0px 20px 15px !important;
			}
		}

		input[type=number]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}

		.editMoneyNotice {
			background: rgba(253, 246, 236, 1);
			color: rgba(253, 162, 60, 1);
			padding: 10px;
			word-wrap: break-word;
			width: 95%;
			margin-top: 10px;
		}
	}

	.el-alert {
		.el-alert__content {
			width: 100%;
		}

		.el-alert__title {
			word-wrap: break-word;
		}
	}
</style>
